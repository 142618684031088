import React, { useEffect, useState } from "react"
import Request from "./Request"
import "antd/dist/antd.less"

import { Affix, Button, Row, Col, Divider } from "antd"
import omnivaWhite from "images/omniva-new-logo-white.svg"
import tntWhite from "images/tnt-white.png"
import dpdWhite from "images/dpd-white.png"
import upsWhite from "images/ups-white.png"
import paypal from "images/paypal-partner.svg"
import visa from "images/visa-partner.svg"
import paysera from "images/paysera-partner.svg"
import masterCard from "images/mastercard-partner.svg"
import glsWhite from "images/gls.svg"
import posteWhite from "images/poste.svg"
import fbLogo from "images/fb.svg"
import instaLogo from "images/ig.svg"
import ytLogo from "images/yt.svg"
import linkedInLogo from "images/linked.svg"
import dropDownCircle from "images/dropdown-circle.svg"
import arrowCouriers from "images/arrow-couriers.svg"
import { useMediaQuery } from "react-responsive"
import "./styles.less"

import { Collapse } from "antd"

const { Panel } = Collapse

const Footer = ({ ruby = false }) => {
  const [footerData, setFooterData] = useState()
  const [loading, setLoading] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const [showMore1, setShowMore1] = useState(false)
  const [showMore2, setShowMore2] = useState(false)
  const [showMore3, setShowMore3] = useState(false)
  const [showMore4, setShowMore4] = useState(false)
  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  useEffect(() => {
    Request("/footer", "GET").then((response) => {
      setFooterData(response)
    })
  }, [])

  useEffect(() => {
    if (footerData) setLoading(false)
  })

  return (
    <>
      <div
        style={{
          marginTop: ruby ? "20px" : "200px",
          marginBottom: "50px",
          backgroundColor: "#F89823",
          position: "relative",
        }}
        className="footer-tsx"
      >
        {!loading && (
          <>
            <Row gutter={{ lg: 32 }} style={{
              height: isMobileScreen ? "max-content" : showMore ? "max-content" : "210px",
              overflow: "hidden",
              position: "relative",
              padding: isMobileScreen && "0 16px",
            }}>
              {(!showMore && !isMobileScreen) && (
                <div className="linear-bg"></div>
              )}
              {!isMobileScreen && (
                <div
                  className="show-more"
                  onClick={() => {
                    setShowMore(!showMore)
                  }}
                >
                  {!showMore ? (
                    <>
                      {"Rodyti daugiau "}
                      <i className="fa fa-lg fa-angle-down"></i>
                    </>
                  ) : (
                    <>
                      {"Rodyti mažiau "}
                      <i className="fa fa-lg fa-angle-up"></i>
                    </>
                  )}
                </div>
              )}
              <Col span={24} lg={{ offset: 2, span: 5 }}>
                <div className="column-title" style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                  <p style={{
                    margin: 0
                  }}>
                    {"Eksportas į šalis"}
                  </p>
                  {isMobileScreen && (
                    <img src={dropDownCircle} onClick={() => {
                      setShowMore1(!showMore1)
                    }} style={{
                      transform: showMore1 ? "rotate(180deg)" : "rotate(0deg)",
                    }} />
                  )}
                </div>
                {isMobileScreen ? (
                  <>
                    {showMore1 && (
                      <>
                        {
                          footerData.data.service_pages_to_country.map(
                            (service, index) => {
                              return (
                                <div style={{ marginBottom: "10px" }} key={service.title}>
                                  <a
                                    className="column-link"
                                    href={`/services/${service.link}`}
                                  >
                                    {service.title}
                                  </a>
                                </div>
                              )
                            }
                          )
                        }
                      </>
                    )
                    }
                  </>
                ) : (
                  <>
                    {footerData.data.service_pages_to_country.map(
                      (service, index) => {
                        return (
                          <div style={{ marginBottom: "10px" }} key={service.title}>
                            <a
                              className="column-link"
                              href={`/services/${service.link}`}
                            >
                              {service.title}
                            </a>
                          </div>
                        )
                      }
                    )
                    }
                  </>
                )}
              </Col>

              <Col span={24} lg={5}>
                <div className="column-title" style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                  <p style={{
                    margin: 0
                  }}>
                    {"Importas iš šalių"}
                  </p>
                  {isMobileScreen && (
                    <img src={dropDownCircle} onClick={() => {
                      setShowMore2(!showMore2)
                    }} style={{
                      transform: showMore2 ? "rotate(180deg)" : "rotate(0deg)",
                    }} />
                  )}
                </div>
                {isMobileScreen ? (
                  <>
                    {showMore2 && (
                      <>
                        {
                          footerData.data.service_pages_from_country.map(
                            (service, index) => {
                              return (
                                <div style={{ marginBottom: "10px" }} key={service.title}>
                                  <a
                                    className="column-link"
                                    href={`/services/${service.link}`}
                                  >
                                    {service.title}
                                  </a>
                                </div>
                              )
                            }
                          )
                        }
                      </>
                    )
                    }
                  </>
                ) : (
                  <>
                    {footerData.data.service_pages_from_country.map(
                      (service, index) => {
                        return (
                          <div style={{ marginBottom: "10px" }} key={service.title}>
                            <a
                              className="column-link"
                              href={`/services/${service.link}`}
                            >
                              {service.title}
                            </a>
                          </div>
                        )
                      }
                    )
                    }
                  </>
                )}
              </Col>

              <Col span={24} lg={10}>
                <Row gutter={32}>
                  <Col span={24} lg={12}>
                    <div className="column-title" style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                      {isMobileScreen ? (
                        <>
                          <a href="/kurjeriai" style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            color: "#fff",
                            width: "100%",
                          }}>
                            <p style={{
                              margin: 0
                            }}>
                              {"Kurjeriai"}
                            </p>
                            <img src={arrowCouriers} />
                          </a>
                          <img src={dropDownCircle} onClick={() => {
                            setShowMore3(!showMore3)
                          }} style={{
                            transform: showMore3 ? "rotate(180deg)" : "rotate(0deg)",
                          }} />
                        </>
                      ) : (
                        <a href="/kurjeriai" style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "#fff",
                          width: "100%",
                        }}>
                          <p style={{
                            margin: 0
                          }}>
                            {"Kurjeriai"}
                          </p>
                          <img src={arrowCouriers} />
                        </a>
                      )}
                    </div>
                    {isMobileScreen ? (
                      <>
                        {showMore3 && (
                          <>
                            {footerData.data.terms_couriers_pages_1.map((service) => (
                              <div style={{ marginBottom: "10px" }} key={service.title}>
                                <a
                                  className="column-link"
                                  href={`/terms_couriers_pages/${service.link}`}
                                >
                                  {service.title}
                                </a>
                              </div>
                            ))}
                          </>
                        )
                        }
                      </>
                    ) : (
                      <>
                        {footerData.data.terms_couriers_pages_1.map((service) => (
                          <div style={{ marginBottom: "10px" }} key={service.title}>
                            <a
                              className="column-link"
                              href={`/terms_couriers_pages/${service.link}`}
                            >
                              {service.title}
                            </a>
                          </div>
                        ))}
                      </>
                    )}
                  </Col>

                  <Col span={24} lg={12}>
                    {/* <div className="column-title">{"Apie mus"}</div> */}
                    <div className="column-title" style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                      {isMobileScreen ? (
                        <>
                          <a href="/about" style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            color: "#fff",
                            width: "100%",
                          }}>
                            <p style={{
                              margin: 0
                            }}>
                              {"Apie mus"}
                            </p>
                            <img src={arrowCouriers} />
                          </a>
                          <img src={dropDownCircle} onClick={() => {
                            setShowMore4(!showMore4)
                          }} style={{
                            transform: showMore4 ? "rotate(180deg)" : "rotate(0deg)",
                          }} />
                        </>
                      ) : (
                        <a href="/about" style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "#fff",
                          width: "100%",
                        }}>
                          <p style={{
                            margin: 0
                          }}>
                            {"Apie mus"}
                          </p>
                          <img src={arrowCouriers} />
                        </a>
                      )}
                    </div>
                    {isMobileScreen ? (
                      <>
                        {showMore4 && (
                          <>
                            <div style={{ marginBottom: "10px" }}>
                              <a className="column-link" href="/about">
                                {"Mūsų įmonė"}
                              </a>
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <a className="column-link" href="/atsiliepimai">
                                {"Atsiliepimai"}
                              </a>
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <a className="column-link" href="/terms">
                                {"Terminai ir sąlygos"}
                              </a>
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <a
                                className="column-link"
                                href="/terms_couriers_pages/privatumo-politika"
                              >
                                {"Privatumo Politika"}
                              </a>
                            </div>

                            <div style={{ marginBottom: "10px" }}>
                              <a className="column-link" href="/duk">
                                {"D.U.K."}
                              </a>
                            </div>

                            {footerData.data.terms_couriers_pages_2.map(
                              (service, index) => {
                                return (
                                  <div
                                    style={{ marginBottom: "10px" }}
                                    key={service.title}
                                  >
                                    <a
                                      className="column-link"
                                      href={`/terms_couriers_pages/${service.link}`}
                                    >
                                      {service.title}
                                    </a>
                                  </div>
                                )
                              }
                            )}
                            <div style={{ marginBottom: "10px" }}>
                              <a className="column-link" href="/campaigns">
                                {"Pasiūlymai"}
                              </a>
                            </div>
                          </>
                        )
                        }
                      </>
                    ) : (
                      <>
                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/about">
                            {"Mūsų įmonė"}
                          </a>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/atsiliepimai">
                            {"Atsiliepimai"}
                          </a>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/services">
                            {"Teikiamos paslaugos"}
                          </a>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/terms">
                            {"Terminai ir sąlygos"}
                          </a>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/duk">
                            {"D.U.K."}
                          </a>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/blogs">
                            {"Naujienos"}
                          </a>
                        </div>

                        {footerData.data.terms_couriers_pages_2.map(
                          (service, index) => {
                            return (
                              <div
                                style={{ marginBottom: "10px" }}
                                key={service.title}
                              >
                                <a
                                  className="column-link"
                                  href={`/terms_couriers_pages/${service.link}`}
                                >
                                  {service.title}
                                </a>
                              </div>
                            )
                          }
                        )}

                        <div style={{ marginBottom: "10px" }}>
                          <a className="column-link" href="/campaigns">
                            {"Pasiūlymai"}
                          </a>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={!isMobileScreen && 32} style={{
              background: "linear-gradient(180deg, #FFAC46 0%, #E1820E 100%), #F2921D",
              backgroundRepeat: "no-repeat",
            }}>
              <Col span={24} lg={{ offset: 2, span: 20 }}>
                <Row gutter={!isMobileScreen && 32} style={{
                  padding: isMobileScreen && "0 16px",
                }}>
                  <Col span={24} lg={6}>
                    <div className="column-title">{"Mokėjimais rūpinasi"}</div>

                    <div style={{ maxWidth: 400, marginTop: "2.25rem" }}>
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                        <img src={masterCard} style={{ width: isMobileScreen ? 70 : 50 }} className='img-responsive' />
                        <img src={visa} style={{ width: isMobileScreen ? 70 : 50 }} className='img-responsive' />
                        <img src={paysera} style={{ width: isMobileScreen ? 70 : 50 }} className='img-responsive' />
                        <img src={paypal} style={{ width: isMobileScreen ? 70 : 50 }} className='img-responsive' />
                      </div>
                    </div>
                  </Col>
                  <Col span={24} lg={12}>
                    <div className="column-title">{"Partneriai"}</div>
                    <div className="partners-icons">
                      <img src={omnivaWhite} />
                      <img src={tntWhite} />
                      <img src={dpdWhite} />
                      <img src={upsWhite} />
                      <img src={glsWhite} />
                      <img src={posteWhite} />
                    </div>
                  </Col>
                  <Col span={24} lg={6}>
                    <div className="column-title">{"Sekite mus"}</div>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        gap: "4rem",
                        marginTop: "2.25rem"
                      }}
                    >
                      <a
                        href="https://www.facebook.com/siusk24"
                        target="_blank"
                      >
                        <img src={fbLogo} />
                      </a>

                      <a
                        href="https://www.instagram.com/siusk24/"
                        target="_blank"
                      >
                        <img src={instaLogo} />
                      </a>

                      <a
                        href="https://www.linkedin.com/company/siusk24/"
                        target="_blank"
                      >
                        <img src={linkedInLogo} />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/siusk24/"
                        target="_blank"
                      >
                        <img src={ytLogo} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{
                marginTop: "2rem",
                textAlign: "center",
                color: "white",
                marginBottom: "2rem"
              }}>
                <p> Copyright © <strong>Siusk24</strong> 2023 | Visos teisės saugomos. </p>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  )
}

export default Footer
