import React, { useState, useEffect } from "react"

import { Row, Col, Switch, Space, Spin, Modal } from "antd"
import introImage from "images/landing-page/hero.svg"
import hiveLeft from "images/landing-page/hive-left.png"
import hiveRight from "images/landing-page/hive-right.png"
import newsletterBg from "images/newsletter-bg.svg"
import closeImg from "images/close-img.svg"
import clarityImg from "images/clarity.svg"
import landingCheckMark from "images/landing-check.svg"
import { Formik, useFormikContext } from "formik"
import Aos from "aos"
import "aos/dist/aos.css"
import { Form, Input, Select, Field } from "formik-antd"
import * as yup from "yup"
import RandExp from "randexp"
import { POSTCODE_REGEXES } from "../../helpers/countryCodes"
import Request from "../../helpers/Request"
import { useMediaQuery } from "react-responsive"

const { Option } = Select

const MainHeader = ({ countries, COUNTRY_FLAGS }) => {
  const [zipCodeExample, setZipCodeExample] = useState([])
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [newsletterText, setNewsletterText] = useState("");

  const isMobileScreen = useMediaQuery({ query: "(max-width: 992px)" })

  const [searchCountries, setSearchCountries] = useState([])
  const [accountInfo, setAccountInfo] = useState([])

  useEffect(() => {
    Request("accounts/account_details", "GET")
      .then((response) => {
        if (response.account_details) setAccountInfo(response.account_details)
      })
      .catch(({ errors }) => {
        console.log("errors from catch: ", errors)
      })
  }, [])

  useEffect(() => {
    Request("/newsletter_info", "GET")
      .then((response) => {
        if (response.show_newsletter_modal == true && getCookie("newsletter") != "true") {
          setNewsletterText(response.newsletter_text);
          setTimeout(() => {
            setIsNewsletterModalOpen(true);
          }, 2000);
        }
      })
      .catch(({ errors }) => {
        console.log("errors from catch: ", errors)
      })
  }, [])

  const getCookie = function (name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  const handleCancel = () => {
    setIsNewsletterModalOpen(false);
    document.cookie = "newsletter=true; expires=Thu, 18 Dec 2023 12:00:00 UTC; path=/";
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const newsletterInitialValues = {
    newsletter: {
      email: "",
    },
  }

  const initialValues = {
    order: {
      ignore_build_items: true,
      google_autocomplete: true,
      google_sender: "Lietuva",
      google_receiver: "",
      sender_country_id: 122,
      sender_postal_code: "",
      receiver_country_id: "",
      receiver_postal_code: "",
      items_attributes: [
        {
          x: "",
          y: "",
          z: "",
          weight: "",
          amount: 1,
        },
      ],
      commit: "Skaičiuoti kainą",
    },
  }

  const validateZipCode = (value, { options, createError, path }, type) => {
    let country = { code: "" }

    if (type == "sender_country_id") {
      country = countries.find(
        ({ id }) => id === options?.from[0]?.value?.sender_country_id
      )
    } else {
      country = countries.find(
        ({ id }) => id === options?.from[0]?.value?.receiver_country_id
      )
    }

    if (country && value) {
      let regExample = zipCodeExample.find(({ code }) => code === country.code)

      const regex = POSTCODE_REGEXES[country.code]
      if (regex) {
        const randexp = new RandExp(regex)
        if (!regex.test(value)) {
          if (!regExample) {
            regExample = { code: country.code, example: randexp.gen() }
            setZipCodeExample([...zipCodeExample, regExample])
          }
          return createError({
            path,
            message: `Pvz.: ${regExample.example}`,
          })
        } else return true
      } else return true
    } else return true
  }

  const newsletterSchema = yup.object().shape({
    newsletter: yup.object().shape({
      email: yup
        .string()
        .max(50, "Daugiausia 50 simbolių")
        .matches(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])$/, "Neteisingas el. pašto adresas")
        .required("Privaloma"),
    }),
  })

  const calculatorSchema = yup.object().shape({
    order: yup.object().shape({
      sender_country_id: yup.number().required("Privaloma"),
      receiver_country_id: yup.number().required("Privaloma"),

      sender_postal_code: yup
        .string()
        .max(50, "maximum 50 characters")
        .test("zip-code", (value, { options, createError, path }) =>
          validateZipCode(
            value,
            { options, createError, path },
            "sender_country_id"
          )
        ),

      receiver_postal_code: yup
        .string()
        .max(50, "maximum 50 characters")
        .test("zip-code", (value, { options, createError, path }) =>
          validateZipCode(
            value,
            { options, createError, path },
            "receiver_country_id"
          )
        ),

      items_attributes: yup.array().of(
        yup.object().shape({
          x: yup
            .number()
            .integer("Įveskite cm be kablelio")
            .typeError("Įveskite cm be kablelio")
            .max(270, "max 270")
            .required("Privaloma"),
          y: yup
            .number()
            .integer("Įveskite cm be kablelio")
            .typeError("Įveskite cm be kablelio")
            .max(270, "max 270")
            .required("Privaloma"),
          z: yup
            .number()
            .integer("Įveskite cm be kablelio")
            .typeError("Įveskite cm be kablelio")
            .max(270, "max 270")
            .required("Privaloma"),
          weight: yup
            .number()
            .transform((_value, originalValue) =>
              Number(originalValue.replace(/,/g, "."))
            )
            .max(70, "max 70")
            .typeError("Privalo būti skaičiai")
            .required("Privaloma"),

          // Privalo būti skaičius
        })
      ),
    }),
  })

  useEffect(() => {
    countriesIndexation()
  }, [])

  const countriesIndexation = () => {
    let countriesArray = []

    countries.map((country) => {
      countriesArray.push({ key_word: country.name, id: country.id })
      countriesArray.push({ key_word: country.en_name, id: country.id })

      country.aliases.map((alias) => {
        countriesArray.push({ key_word: alias, id: country.id })
      })

      country.states.map((state) => {
        countriesArray.push({ key_word: state, id: country.id })
      })
    })

    setSearchCountries(countriesArray)
  }

  const IsCountryMatching = (input, id, objects) => {
    const result = objects.filter((object) => object.id == id)

    const searched = result.find((res) =>
      res.key_word.toLowerCase().includes(input.toLowerCase())
    )

    return searched ? true : false
  }

  return (
    <>
      <div className="header-background"></div>

      <div style={{ position: "relative" }}>
        <img
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            minWidth: 347,
            width: "20%",
          }}
          src={hiveLeft}
        />
      </div>

      <div style={{ position: "relative" }}>
        <img
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            minWidth: 347,
            width: "20%",
          }}
          src={hiveRight}
        />
      </div>

      <Row
        style={{
          height: !isMobileScreen ? "100vh" : "auto",
        }}
      >
        <Col span={24} style={{ height: "100%" }}>
          <Row gutter={{ lg: 16 }} style={{ height: "100%" }}>
            <Col span={24} lg={12} style={{ zIndex: 1 }}>
              <div className="calculator-container">
                <div
                  style={{
                    width: "100%",
                    paddingLeft: isMobileScreen ? "20px" : "60px",
                    paddingRight: isMobileScreen && "20px",
                    float: isMobileScreen ? "left" : "right",
                    marginBottom: isMobileScreen && 50,
                  }}
                  className="calculator-container-child"
                  data-aos={isMobileScreen ? "" : "fade-up"}
                >
                  <div className="header-title">
                    <span style={{
                      color: "#FF7D00"
                    }}>Nr.1 </span>
                    kurjerių brokeris Lietuvoje*
                  </div>

                  <div
                    className="form-border-style"
                    style={{
                      marginTop: "31px",
                      backgroundColor: "white",
                      minWidth: !isMobileScreen && "550px",
                    }}
                  >
                    <div style={{ padding: "28px 24px 47px 24px" }}>
                      <Formik
                        onSubmit={(
                          data,
                          {
                            setSubmitting,
                            setTouched,
                            setErrors,
                            setFieldValue,
                          }
                        ) => {
                          data.order.items_attributes[0].weight =
                            data.order.items_attributes[0].weight.replace(
                              ",",
                              "."
                            )

                          Request("/price", "POST", {
                            body: data,
                          })
                            .then((response) => {
                              window.location.href = "/order_steps"
                            })
                            .catch(({ errors }) => {
                              console.log("errors from catch: ", errors)
                            })
                            .finally(() => { })
                        }}
                        initialValues={initialValues}
                        validationSchema={calculatorSchema}
                      >
                        {({
                          isSubmitting,
                          isValid,
                          setFieldValue,
                          errors,
                          values,
                          touched,
                          submitForm,
                          isInitialValid,
                          validateForm,
                          setSubmitting,
                        }) => (
                          <Form>
                            <Row gutter={16}>
                              <Col span={24} lg={12}>
                                <div style={{ position: "relative" }}>
                                  <div
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      className="grouped-select-right-joint"
                                      style={{ width: "60%" }}
                                    >
                                      <Form.Item name="order[sender_country_id]">
                                        <Select
                                          name="order[sender_country_id]"
                                          size="large"
                                          showArrow={false}
                                          showSearch
                                          onChange={(value, form) => {
                                            setFieldValue(
                                              "order[google_sender]",
                                              form.children.props.children[1]
                                                .props.children
                                            )
                                          }}
                                          filterOption={(input, option) => {
                                            return IsCountryMatching(
                                              input,
                                              option.value,
                                              searchCountries
                                            )
                                          }}
                                        >
                                          {countries.map((country) => (
                                            <Option
                                              key={country.id}
                                              value={country.id}
                                              title={country.en_name}
                                              label={country.code}
                                            >
                                              <Space>
                                                <img
                                                  style={{ maxWidth: "20px" }}
                                                  src={
                                                    COUNTRY_FLAGS[
                                                    `${country.code.toLowerCase()}.png`
                                                    ]
                                                  }
                                                />
                                                <div>{country.name}</div>
                                              </Space>
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </div>

                                    <div
                                      className="grouped-input-left-joint"
                                      style={{ width: "40%" }}
                                    >
                                      <Form.Item name="order[sender_postal_code]">
                                        <Input
                                          name="order[sender_postal_code]"
                                          size="large"
                                          style={{ height: "40px" }}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: 12,
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Paėmimas iš"}
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        left: "calc(60% - 20px)",
                                        pointerEvents: "none",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      <svg
                                        width="8"
                                        height="3"
                                        viewBox="0 0 8 3"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4 3L0.535899 7.04481e-08L7.4641 -4.37544e-07L4 3Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: "calc(60% + 12px)",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Pašto kodas"}
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col span={24} lg={12}>
                                <div style={{ position: "relative" }}>
                                  <div
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div
                                      className="grouped-select-right-joint"
                                      style={{ width: "60%" }}
                                    >
                                      <Form.Item name="order[receiver_country_id]">
                                        <Select
                                          name="order[receiver_country_id]"
                                          size="large"
                                          showSearch
                                          showArrow={false}
                                          onChange={(value, form) => {
                                            setTimeout(() => {
                                              setFieldValue(
                                                "order[google_receiver]",
                                                form.children.props.children[1]
                                                  .props.children
                                              )
                                            }, 1)
                                          }}
                                          filterOption={(input, option) => {
                                            return IsCountryMatching(
                                              input,
                                              option.value,
                                              searchCountries
                                            )
                                          }}
                                        >
                                          {countries.map((country) => (
                                            <Option
                                              key={country.id}
                                              value={country.id}
                                              title={country.en_name}
                                              label={country.code}
                                            >
                                              <Space>
                                                <img
                                                  style={{ maxWidth: "20px" }}
                                                  src={
                                                    COUNTRY_FLAGS[
                                                    `${country.code.toLowerCase()}.png`
                                                    ]
                                                  }
                                                />
                                                <div>{country.name}</div>
                                              </Space>
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </div>

                                    <div
                                      className="grouped-input-left-joint"
                                      style={{ width: "40%" }}
                                    >
                                      <Form.Item name="order[receiver_postal_code]">
                                        <Input
                                          name="order[receiver_postal_code]"
                                          size="large"
                                          style={{ height: "40px" }}
                                        />
                                      </Form.Item>
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: 12,
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Pristatymas į"}
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 10,
                                        left: "calc(60% - 20px)",
                                        pointerEvents: "none",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      <svg
                                        width="8"
                                        height="3"
                                        viewBox="0 0 8 3"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4 3L0.535899 7.04481e-08L7.4641 -4.37544e-07L4 3Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: "calc(60% + 12px)",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Pašto kodas"}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row gutter={16} style={{ marginTop: "29px" }}>
                              <Col span={18}>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: -28,
                                    fontFamily: "Trenda-regular",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  <i className="fa fa-cube"></i>
                                  <strong>{" Pakuotės duomenys "}</strong>
                                </div>
                                <div style={{ position: "relative" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="grouped-input-right-joint"
                                      style={{ width: "33%" }}
                                    >
                                      <Form.Item name="order[items_attributes][0][x]">
                                        <Input
                                          name="order[items_attributes][0][x]"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div
                                      className="grouped-input-left-right-joint"
                                      style={{ width: "33%" }}
                                    >
                                      <Form.Item name="order[items_attributes][0][y]">
                                        <Input
                                          name="order[items_attributes][0][y]"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </div>
                                    <div
                                      className="grouped-input-left-joint"
                                      style={{ width: "33%" }}
                                    >
                                      <Form.Item name="order[items_attributes][0][z]">
                                        <Input
                                          name="order[items_attributes][0][z]"
                                          size="large"
                                        />
                                      </Form.Item>
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: 12,
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Ilgis"}
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: "calc(33% + 12px)",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Plotis"}
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -6,
                                        left: "calc(66% + 12px)",
                                      }}
                                      className="calculator-form-labels"
                                    >
                                      {"Aukštis"}
                                    </div>

                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 13,
                                        left: "calc(100% - 40px)",
                                        zIndex: 5,
                                      }}
                                    >
                                      {"cm"}
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col span={6}>
                                <div style={{ position: "relative" }}>
                                  <Form.Item name="order[items_attributes][0][weight]">
                                    <Input
                                      name="order[items_attributes][0][weight]"
                                      size="large"
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: -6,
                                      left: 12,
                                    }}
                                    className="calculator-form-labels"
                                  >
                                    {"Svoris"}
                                  </div>

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 13,
                                      left: "calc(100% - 30px)",
                                      zIndex: 5,
                                    }}
                                  >
                                    {"kg"}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  textAlign: "center",
                                  top: isMobileScreen ? -5 : 26,
                                }}
                              >
                                <Form.Item name="submit">
                                  <button
                                    className="calculate-price-button"
                                    style={{
                                      position: "unset",
                                      width: isMobileScreen && "100%",
                                    }}
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {!isSubmitting ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div style={{ paddingRight: "5px" }}>
                                          {"Skaičiuoti"}
                                        </div>
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM6.39491 5.60509C6.26243 5.46793 6.18913 5.28422 6.19078 5.09353C6.19244 4.90284 6.26893 4.72043 6.40377 4.58559C6.53861 4.45074 6.72102 4.37426 6.91171 4.3726C7.1024 4.37094 7.28611 4.44425 7.42327 4.57673L10.3324 7.48582C10.4687 7.6222 10.5453 7.80715 10.5453 8C10.5453 8.19285 10.4687 8.3778 10.3324 8.51418L7.42327 11.4233C7.28611 11.5558 7.1024 11.6291 6.91171 11.6274C6.72102 11.6257 6.53861 11.5493 6.40377 11.4144C6.26893 11.2796 6.19244 11.0972 6.19078 10.9065C6.18913 10.7158 6.26243 10.5321 6.39491 10.3949C7.71758 9.07224 7.71758 6.92776 6.39491 5.60509Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <Spin />
                                    )}
                                  </button>
                                </Form.Item>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {!isMobileScreen ? (
              <Col
                span={24}
                lg={12}
                style={{
                  height: "100%",
                  marginLeft: "-20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    marginRight: "100px",
                  }}
                >
                  <img
                    data-aos={"fade-left"}
                    style={{
                      width: "calc(100% + 100px)",
                    }}
                    src="../imgs/langs/lt_orders.svg"
                    className="translate-image"
                  />
                </div>
              </Col>
            ) : (
              <Col span={24}>
                <img
                  style={{
                    width: "100%",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  src={introImage}
                />
              </Col>
            )}
            <Col span={24} style={{
              marginTop: isMobileScreen ? 0 : -100,
            }}>
              <Row style={{
                paddingLeft: isMobileScreen ? 20 : 60,
                paddingRight: isMobileScreen ? 20 : 60,
              }}>
                <Col span={24} lg={8} style={{
                  display: "flex",
                  gap: "5px"
                }}>
                  <img src={landingCheckMark} style={{
                    width: 25,
                    height: 25
                  }} />
                  <p style={{
                    fontSize: 16
                  }}>
                    Sparčiausiai augantis kurjerių brokeris
                  </p>
                </Col>
                <Col span={24} lg={8} style={{
                  display: "flex",
                  gap: "5px"
                }}>
                  <img src={landingCheckMark} style={{
                    width: 25,
                    height: 25
                  }} />
                  <p style={{
                    fontSize: 16
                  }}>
                    Platus kurjerių paslaugų pasirinkimas
                  </p>
                </Col>
                <Col span={24} lg={8} style={{
                  display: "flex",
                  gap: "5px"
                }}>
                  <img src={landingCheckMark} style={{
                    width: 25,
                    height: 25
                  }} />
                  <p style={{
                    fontSize: 16
                  }}>
                    Daugiau nei 500 000 užsakymų per metus
                  </p>
                </Col>
                <Col span={24}>
                  <p style={{
                    fontSize: 12,
                    color: "#285578"
                  }}>
                    * pagal 2022 metų kurjerių brokerių apyvartų duomenis
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal title={false} visible={isNewsletterModalOpen} footer={false} wrapClassName={"newsletter-modal"} onCancel={handleCancel} closeIcon={<img style={{ height: '20px', width: '20px' }} src={closeImg} />}>
        <div className="newsletter-modal-header">
          <img src={newsletterBg} style={{ width: "140px" }} />
          <h4>
            {newsletterText}
          </h4>
        </div>
        <div className="newsletter-modal-body">
          <h5>
            Įvesk el. paštą, kuriuo bus atsiųstas nuolaidos kodas
          </h5>
          <Formik
            onSubmit={(
              data,
              {
                setSubmitting,
                setTouched,
                setErrors,
                setFieldValue,
              }
            ) => {
              Request("/new_newsletter", "POST", {
                body: data,
              })
                .then((response) => {
                  setIsNewsletterModalOpen(false);
                  setIsSuccessModalOpen(true);
                })
                .catch(({ errors }) => {
                  console.log("errors from catch: ", errors)
                  // setErrors(errors)
                  if (errors?.newsletter?.email == "jau užimtas") {
                    setErrors({ newsletter: { email: "Toks el. pašto adresas jau egzistuoja" } })
                  }
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
            initialValues={newsletterInitialValues}
            validationSchema={newsletterSchema}
          >
            {({
              isSubmitting,
              setFieldValue,
              errors,
            }) => (
              <Form className="newsletter-form">
                <Form.Item
                  name="newsletter[email]"
                  label={false}
                >
                  <Input
                    name="newsletter[email]"
                    placeholder="El. paštas"
                    onChange={(e) => {
                      setFieldValue("newsletter[email]", e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item name="submit">
                  <button
                    type="submit"
                    className="newsletter-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spin />
                    ) : (
                      "Gauti nuolaidą"
                    )}
                  </button>
                </Form.Item>
                <span>
                  Registruodamas el. paštą sutinki gauti www.siusk24.lt naujienas bei pranešimus. Jų gali atsisakyti bet kuriuo metu.
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal title={false} visible={isSuccessModalOpen} footer={false} wrapClassName={"newsletter-modal"} onCancel={handleSuccessCancel} closeIcon={<img style={{ height: '20px', width: '20px' }} src={closeImg} />}>
        <div className="newsletter-modal-header">
          <img src={newsletterBg} style={{ width: "140px" }} />
          <h4>
            <img src={clarityImg} style={{ width: "40px" }} />
            Dėkojame!
          </h4>
        </div>
        <div className="newsletter-modal-body">
          <h5>
            Nuolaidos kodas buvo išsiųstas jūsų nurodytu el. paštu.
          </h5>
          <button
            type="submit"
            className="newsletter-btn"
            onClick={() => {
              setIsSuccessModalOpen(false)
            }}
          >
            {"Tęsti"}
          </button>
        </div>
      </Modal>
    </>
  )
}

export default MainHeader
