import React, { useEffect, useState } from "react"
import {
  Menu,
  Dropdown
} from "antd"
import langIcon from "images/lang.svg"
import ltIcon from "images/lt.svg"
import enIcon from "images/en.svg"
import Request from "./Request"

const Translate = () => {
  useEffect(() => {
    // in some cases, the google translate script adds a style to the opening html tag.
    // this added style disables scrolling.
    // the next 3 lines removes this added style in order to re-enable scrolling.
    if (window.document.scrollingElement.hasAttribute("style")) {
      window.document.scrollingElement.setAttribute("style", "");
    }
  });

  const [lang, setLang] = useState('lt');

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, [])

  useEffect(() => {
    var images = document.querySelectorAll('.translate-image');

    images.forEach((image) => {
      var src = image.getAttribute('src');
      var old_lang = src.split("/")[3].split("_")[0];
      image.setAttribute('src', src.replace(old_lang, lang));
    });

    if (lang === MijoraGoogleTrans.defaultLang) {
      let mainBody = document.querySelector('.goog-te-banner-frame');

      if (!mainBody) {
        mainBody = document.querySelector('iframe#\\:1\\.container.skiptranslate');
      }

      if (!mainBody) {
        console.log('MIJORA GOOGLE TRANSLATE', 'Could not locate google translate iframe');
        return;
      }

      Array.from(mainBody.contentDocument.querySelectorAll('a')).forEach((item) => {
        const id = item.id || '';
        if (id.includes('close') || item.classList.contains('goog-close-link')) {
          item.click();
        }
      });

      MijoraGoogleTrans.setCurrentLang(lang);
      return;
    }

    const selector = document.querySelector('.goog-te-combo');

    if (selector.value === lang) {
      return;
    }

    selector.value = lang;

    MijoraGoogleTrans.setCurrentLang(lang);

    // find google closure
    let closure = null;
    Object.keys(selector).forEach(key => {
      if (key.includes('closure_lm_')) {
        closure = selector[key];
      }
    })

    if (closure) {
      Object.keys(closure).forEach(key => {
        if (typeof closure[key].change === 'object') {
          closure[key].change.forEach(obj => obj.listener());
        }
      });
    }

  }, [lang])

  var MijoraGoogleTrans = {
    defaultLang: 'lt',

    setCurrentLang: function (lang_code) {
      setLang(lang_code);
    },

    getLangFromCookie: function () {
      const name = 'googtrans';
      const re = new RegExp(name + "=([^;]+)");
      const value = re.exec(document.cookie);
      return (value != null) ? unescape(value[1]).split('/').pop() : null;
    },

    getGTIframe: function () {
      let mainFrame = document.getElementById('goog-gt-tt'); // since cant look for iframe we looking for this element
      if (mainFrame) {
        return mainFrame;
      }

      mainFrame = document.getElementById('goog-gt-'); // seems to be used in 2023-02-01 update
      if (mainFrame) {
        return mainFrame;
      }

      mainFrame = document.querySelector('iframe#\\:1\\.container.skiptranslate');

      return mainFrame ? mainFrame : null;
    }
  };

  function googleTranslateElementInit() {
    const elementToObserve = document.body;

    const main_observer = new MutationObserver(function () {
      //const mainFrame = document.getElementById(':1.container');
      const mainFrame = MijoraGoogleTrans.getGTIframe();
      if (!mainFrame) {
        console.log('MIJORA GOOGLE TRANSLATE', 'Could not identify google translate activation');
        return;
      }

      MijoraGoogleTrans.mainFrame = mainFrame;

      const cookie_lang = MijoraGoogleTrans.getLangFromCookie();
      if (cookie_lang) {
        MijoraGoogleTrans.setCurrentLang(cookie_lang);
      } else {
        MijoraGoogleTrans.setCurrentLang(MijoraGoogleTrans.defaultLang);
      }

      main_observer.disconnect();
    });

    main_observer.observe(elementToObserve, { subtree: true, childList: true });

    const selector_observer = new MutationObserver(function () {
      const selector = document.querySelector('.goog-te-combo');
      if (selector && selector.value !== undefined) {
        let closure = null;
        selector_observer.disconnect();
      }
    });
    selector_observer.observe(elementToObserve, { subtree: true, childList: true });

    new google.translate.TranslateElement({
      pageLanguage: MijoraGoogleTrans.defaultLang,
      includedLanguages: 'en',
      //layout: google.translate.TranslateElement.InlineLayout.SIMPLE
      layout: google.translate.TranslateElement.FloatPosition.BOTTOM_RIGHT
    },
      'google_translate_element'
    );
  }

  function disableTurboLinks() {
    var links = document.querySelectorAll('a');
    links.forEach((link) => {
      link.setAttribute('data-turbolinks', 'false');
    });
  }

  return (
    <>
      <Dropdown
        overlay={() => (
          <Menu className="lang-dropdown notranslate">
            <Menu.Item onClick={() => {
              disableTurboLinks();
              setLang('lt');
            }}>
              <img src={ltIcon} />
              <span>Lietuvių</span>
            </Menu.Item>
            <Menu.Item onClick={() => {
              disableTurboLinks();
              setLang('en');
            }}>
              <img src={enIcon} />
              <span>English</span>
            </Menu.Item>
          </Menu>
        )}
        placement="bottomRight"
      >
        <div className="lang-menu notranslate">
          <img src={langIcon} />
          <span>{lang.toUpperCase()}</span>
          <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.96623 1.04116L3.49845 4.27792C3.26435 4.57403 2.90345 4.57403 2.67911 4.27792L0.211327 1.04116C-0.0227702 0.734844 0.0942784 0.5 0.455179 0.5H5.72238C6.09303 0.5 6.20033 0.734844 5.96623 1.04116Z" fill="#285578" />
          </svg>
        </div>
      </Dropdown>
      <div id="google_translate_element"></div>
    </>
  );
}

export default Translate
